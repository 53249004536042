import { defineStore } from 'pinia'

export const useUtmStore = defineStore('utmStore', () => {
  const list = ref([])

  const add = utm => {
    if (!list.value.includes(utm)) list.value.push(utm)
  }
  const remove = utm => {
    list.value.splice(list.value?.indexOf(utm), 1)
  }

  return {
    list,
    add,
    remove,
  }
})
