import type { RouterConfig } from '@nuxt/schema'

/*
This router rewrites routes for pages under club-domain & root-domain directories
It's for club feature using StockPro subdomains. Some url paths load different page content.
If a page is moved under a domain directory, make sure to have it's counterpart in the club or root directory
*/

// Inspired from https://ynechaev.medium.com/subdomains-with-nuxt-js-483df826c788

export default <RouterConfig>{
  routes: _routes => {
    const config = useRuntimeConfig()
    const nuxtApp = useNuxtApp()
    // console.log('import.meta', import.meta)

    let routesDirectory = 'root-domain'

    function setRoutesDirectory(subpaths: string[]) {
      const isClubDomain =
        subpaths.length > 2 &&
        !['localhost', 'www', 'pro', 'intl', 'staging', 'v3', config.public.ENV_NAME?.toLowerCase()].includes(
          subpaths[0]
        )
      const isProDomain = subpaths.length > 2 && subpaths[0] === 'pro'

      // Get routes directory based on host and subdomain presence
      if (isClubDomain) {
        routesDirectory = 'club-domain'
      } else if (isProDomain) {
        routesDirectory = 'pro-domain'
      }
    }

    if (import.meta.server && nuxtApp.ssrContext && nuxtApp.ssrContext.nuxt && nuxtApp.ssrContext.event) {
      // console.log('ssrContext', nuxtApp.ssrContext)
      const subpaths = nuxtApp.ssrContext?.event?.node?.req?.headers?.host?.split('.') || []
      // console.log(subpaths)
      setRoutesDirectory(subpaths)
      // routesDirectory = isClubDomain ? 'club-domain' : 'root-domain'
      // console.log('routesDirectory', routesDirectory)
      nuxtApp.ssrContext.nuxt.routesDirectory = routesDirectory
    }
    if (import.meta.client) {
      // Get what we saved on SSR
      // if (window.__NUXT__ && window.__NUXT__.routesDirectory) {
      //   routesDirectory = window.__NUXT__.routesDirectory
      // }
      const subpaths =
        import.meta.url
          .replace(/https?:\/\//, '')
          ?.split(':')
          .join('.')
          ?.split('.') || []
      if (
        subpaths.length > 2 &&
        !['www', 'intl', 'staging', 'v3', config.public.ENV_NAME?.toLowerCase()].includes(subpaths[0])
      ) {
        // console.log('subpaths', subpaths)
        setRoutesDirectory(subpaths)
      }
    }

    function isUnderDirectory(route: { path: string; name: string }, directory: string) {
      const path = route.path
      return path === '/' + directory || path.startsWith('/' + directory + '/')
    }

    let newRoutes = []
    // console.log(routesDirectory)
    if (routesDirectory) {
      newRoutes = _routes
        .filter(route => {
          // Exclude duplicated routes based on opposite routesDirectory
          const excludedDirectory = routesDirectory === 'club-domain' ? 'root-domain' : 'club-domain'
          return !isUnderDirectory(route, excludedDirectory)
        })
        .map(route => {
          // Remove domain directory in path and name
          if (isUnderDirectory(route, routesDirectory)) {
            return {
              ...route,
              path: route.path?.substring(routesDirectory.length + 1) || '/',
              name: String(route.name)?.substring(routesDirectory.length + 1) || 'index',
            }
          }
          return route
        })
    }
    // console.log('routesDirectory', routesDirectory)
    // console.log('newRoutes', newRoutes)
    return newRoutes
  },
}
