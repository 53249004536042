import { defineStore } from 'pinia'

export const useIntersectionStore = defineStore('intersectionStore', {
  state: () => ({
    observer: null,
    targets: new Map(),
  }),

  actions: {
    initObserver(callback, options = { rootMargin: '0px', threshold: 0 }) {
      if (this.observer) return // Avoid duplicate
      this.observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          // console.log('Observed entry:', entry.target)
          const targetCallback = this.targets.get(entry.target)
          if (targetCallback) {
            targetCallback(entry)
          }
        })
      }, options)
    },
    observe(element, callback) {
      if (!this.observer) {
        throw new Error('IntersectionObserver not initialized')
      }
      if (this.targets.has(element)) {
        // console.log('Element is already being observed')
        return
      }
      // console.log('Observing element:', element)
      this.targets.set(element, callback)
      this.observer.observe(element)
    },
    unobserve(element) {
      if (this.targets.has(element)) {
        this.targets.delete(element)
        this.observer.unobserve(element)
      }
    },
    clean() {
      if (this.observer) {
        this.observer.disconnect()
        this.observer = null
        this.targets.clear()
      }
    },
  },
})
