import { default as _91article_93j1jNaik8FFMeta } from "/app/pages/blog/[article].vue?macro=true";
import { default as _91category_93K9TdwpncnAMeta } from "/app/pages/blog/category/[category].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as _91tag_93SNY0TUNIv8Meta } from "/app/pages/blog/tag/[tag].vue?macro=true";
import { default as _91brand_93hBc9Ku8MJSMeta } from "/app/pages/brand/[brand].vue?macro=true";
import { default as indexooJswcUi1vMeta } from "/app/pages/brands/index.vue?macro=true";
import { default as checkout4YA79jVi1xMeta } from "/app/pages/cart/checkout.vue?macro=true";
import { default as _91uid_93EUjtKh79RbMeta } from "/app/pages/cart/confirmation/[uid].vue?macro=true";
import { default as _91uid_93vs94muOnKjMeta } from "/app/pages/cart/confirmation/bancontact/[uid].vue?macro=true";
import { default as _91uid_93Tf8oHLdMHvMeta } from "/app/pages/cart/confirmation/ideal/[uid].vue?macro=true";
import { default as _91uid_93zDNM9jcw7wMeta } from "/app/pages/cart/confirmation/paypal/[uid].vue?macro=true";
import { default as indexAacQV8oPDMMeta } from "/app/pages/cart/index.vue?macro=true";
import { default as logineBjEt6PfwAMeta } from "/app/pages/cart/login.vue?macro=true";
import { default as payment_completeeaYJm1q1sFMeta } from "/app/pages/cart/payment_complete.vue?macro=true";
import { default as _91slug_934s7wUcyk9UMeta } from "/app/pages/category/[slug].vue?macro=true";
import { default as _91slug_93wgHwBr2tgwMeta } from "/app/pages/category/best-deals/[slug].vue?macro=true";
import { default as indexTNUPTnasHkMeta } from "/app/pages/category/best-deals/index.vue?macro=true";
import { default as _91slug_93J1ES9ES4EiMeta } from "/app/pages/category/best-sellers/[slug].vue?macro=true";
import { default as indexftpEyktn3sMeta } from "/app/pages/category/best-sellers/index.vue?macro=true";
import { default as _91slug_93e51aXsP7LeMeta } from "/app/pages/category/brand/[slug].vue?macro=true";
import { default as indexihCrXpRg16Meta } from "/app/pages/category/green-everyday/index.vue?macro=true";
import { default as _91slug_93uBFA3WwjJgMeta } from "/app/pages/category/new-products/[slug].vue?macro=true";
import { default as indexcmMufNXU4EMeta } from "/app/pages/category/new-products/index.vue?macro=true";
import { default as indexL6RgyuwLfGMeta } from "/app/pages/category/rooms/index.vue?macro=true";
import { default as indexnbgCEbdYY4Meta } from "/app/pages/category/seasonal-selections/index.vue?macro=true";
import { default as indexXasG3ROtP8Meta } from "/app/pages/category/special-discounts/index.vue?macro=true";
import { default as cguHWppEe3fB9Meta } from "/app/pages/cgu.vue?macro=true";
import { default as cguMobileQonVbgA1udMeta } from "/app/pages/cguMobile.vue?macro=true";
import { default as cgvqMKWudsQH7Meta } from "/app/pages/cgv.vue?macro=true";
import { default as cgvMobileOq2RhxLqFNMeta } from "/app/pages/cgvMobile.vue?macro=true";
import { default as indexmtumf81DIiMeta } from "/app/pages/club-domain/index.vue?macro=true";
import { default as indexhgvuRRuzCOMeta } from "/app/pages/coming-soon/index.vue?macro=true";
import { default as indexhg2nWJrbRRMeta } from "/app/pages/dashboard/index.vue?macro=true";
import { default as _91token_93Q8bFOYrUvmMeta } from "/app/pages/download/monthly-report/[token].vue?macro=true";
import { default as _91token_93qUPfi3IVFcMeta } from "/app/pages/download/white-book/[token].vue?macro=true";
import { default as indexBYQ6pC1gbeMeta } from "/app/pages/login/activation/index.vue?macro=true";
import { default as indexgK5VicK7y2Meta } from "/app/pages/login/index.vue?macro=true";
import { default as indexEdWVEiPDzoMeta } from "/app/pages/login/set-password/index.vue?macro=true";
import { default as _91slugCategory_93vuPVBjHgtvMeta } from "/app/pages/merchant/[slug]/[slugCategory].vue?macro=true";
import { default as indexcnqfG2VYxUMeta } from "/app/pages/merchant/[slug]/about-us/index.vue?macro=true";
import { default as indexqzxBxLC014Meta } from "/app/pages/merchant/[slug]/home/index.vue?macro=true";
import { default as indexNmxixrLNStMeta } from "/app/pages/merchant/[slug]/index.vue?macro=true";
import { default as productsU0iQHpOwjsMeta } from "/app/pages/merchant/[slug]/products.vue?macro=true";
import { default as _91keywords_93wlwrGmnC0qMeta } from "/app/pages/merchant/[slug]/search/[keywords].vue?macro=true";
import { default as missionTOO9rz5BZdMeta } from "/app/pages/mission.vue?macro=true";
import { default as _91uid_93ZPLIH5HlDXMeta } from "/app/pages/my-account/orders/[uid].vue?macro=true";
import { default as indexg0spLlUUW1Meta } from "/app/pages/my-account/orders/index.vue?macro=true";
import { default as _91uid_932CEmWkynyEMeta } from "/app/pages/my-account/quotes-sales/[uid].vue?macro=true";
import { default as indexuRp838sHoZMeta } from "/app/pages/my-account/quotes-sales/index.vue?macro=true";
import { default as _91uid_93Kaj3s2Ea63Meta } from "/app/pages/my-account/quotes/[uid].vue?macro=true";
import { default as indexuDpG6CuNOvMeta } from "/app/pages/my-account/quotes/index.vue?macro=true";
import { default as _91uid_93i9Ltw0WlGnMeta } from "/app/pages/my-account/sales/[uid].vue?macro=true";
import { default as indexK0UD6KfaxYMeta } from "/app/pages/my-account/sales/index.vue?macro=true";
import { default as _91uid_93bw0lGuoLfyMeta } from "/app/pages/my-account/sales/issues/[uid].vue?macro=true";
import { default as _91article_93qHxrU9j9CMMeta } from "/app/pages/press/[article].vue?macro=true";
import { default as indexpFMFCyrWRdMeta } from "/app/pages/press/index.vue?macro=true";
import { default as privacyg3gwBRGXatMeta } from "/app/pages/privacy.vue?macro=true";
import { default as privacyMobileQRsdlb5UqwMeta } from "/app/pages/privacyMobile.vue?macro=true";
import { default as indexYaOlSid8nXMeta } from "/app/pages/pro-domain/contact/index.vue?macro=true";
import { default as index7SZPfOa7hjMeta } from "/app/pages/pro-domain/index.vue?macro=true";
import { default as _91slug_93OeRp0BK269Meta } from "/app/pages/pro-domain/offers/[slug].vue?macro=true";
import { default as _91slug_93ESdJN2DAltMeta } from "/app/pages/pro-domain/services/[slug].vue?macro=true";
import { default as indexFpixClkMa4Meta } from "/app/pages/pro-domain/tarifs/index.vue?macro=true";
import { default as _91slug_93FPU7I0zot8Meta } from "/app/pages/product/[slug].vue?macro=true";
import { default as index18IXo8oN8QMeta } from "/app/pages/products/index.vue?macro=true";
import { default as qrcodeOOn1CFOUWvMeta } from "/app/pages/qrcode.vue?macro=true";
import { default as download_45premiumu9x2VpkD2WMeta } from "/app/pages/root-domain/download-premium.vue?macro=true";
import { default as downloadYELOg7MyZCMeta } from "/app/pages/root-domain/download.vue?macro=true";
import { default as index2FtfahU8hnMeta } from "/app/pages/root-domain/index.vue?macro=true";
import { default as _91slug_93WJ5cQrUxL2Meta } from "/app/pages/root-domain/landing/[slug].vue?macro=true";
import { default as boost_45my_45salesKePKmfnb3rMeta } from "/app/pages/root-domain/landing/boost-my-sales.vue?macro=true";
import { default as indexzKginzBMFmMeta } from "/app/pages/root-domain/landing/index.vue?macro=true";
import { default as _91email_93h4aoY6XJu5Meta } from "/app/pages/root-domain/my-account/mkp-alerts/[uid]/unsubscribe/email/[email].vue?macro=true";
import { default as indexWOuS1vFrOTMeta } from "/app/pages/root-domain/my-account/mkp-alerts/[uid]/unsubscribe/index.vue?macro=true";
import { default as indexdv1xLUaweKMeta } from "/app/pages/root-domain/my-account/mkp-alerts/index.vue?macro=true";
import { default as indext3zoVQZkQLMeta } from "/app/pages/root-domain/my-account/onboarding/index.vue?macro=true";
import { default as _91partner_93I7GK12qxzqMeta } from "/app/pages/root-domain/partner/[partner].vue?macro=true";
import { default as indexTzCAr4MADkMeta } from "/app/pages/root-domain/stock/index.vue?macro=true";
import { default as subscriptiontxHJK8ZmAXMeta } from "/app/pages/root-domain/subscription.vue?macro=true";
import { default as _91keywords_93SEAtR1bDafMeta } from "/app/pages/search/[keywords].vue?macro=true";
import { default as indexxLsScIFze3Meta } from "/app/pages/signup/index.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "blog-article",
    path: "/blog/:article()",
    meta: _91article_93j1jNaik8FFMeta || {},
    component: () => import("/app/pages/blog/[article].vue").then(m => m.default || m)
  },
  {
    name: "blog-category-category",
    path: "/blog/category/:category()",
    meta: _91category_93K9TdwpncnAMeta || {},
    component: () => import("/app/pages/blog/category/[category].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexgWaMlevJ7RMeta || {},
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-tag-tag",
    path: "/blog/tag/:tag()",
    meta: _91tag_93SNY0TUNIv8Meta || {},
    component: () => import("/app/pages/blog/tag/[tag].vue").then(m => m.default || m)
  },
  {
    name: "brand-brand",
    path: "/brand/:brand()",
    meta: _91brand_93hBc9Ku8MJSMeta || {},
    component: () => import("/app/pages/brand/[brand].vue").then(m => m.default || m)
  },
  {
    name: "brands",
    path: "/brands",
    meta: indexooJswcUi1vMeta || {},
    component: () => import("/app/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: "cart-checkout",
    path: "/cart/checkout",
    meta: checkout4YA79jVi1xMeta || {},
    component: () => import("/app/pages/cart/checkout.vue").then(m => m.default || m)
  },
  {
    name: "cart-confirmation-uid",
    path: "/cart/confirmation/:uid()",
    meta: _91uid_93EUjtKh79RbMeta || {},
    component: () => import("/app/pages/cart/confirmation/[uid].vue").then(m => m.default || m)
  },
  {
    name: "cart-confirmation-bancontact-uid",
    path: "/cart/confirmation/bancontact/:uid()",
    meta: _91uid_93vs94muOnKjMeta || {},
    component: () => import("/app/pages/cart/confirmation/bancontact/[uid].vue").then(m => m.default || m)
  },
  {
    name: "cart-confirmation-ideal-uid",
    path: "/cart/confirmation/ideal/:uid()",
    meta: _91uid_93Tf8oHLdMHvMeta || {},
    component: () => import("/app/pages/cart/confirmation/ideal/[uid].vue").then(m => m.default || m)
  },
  {
    name: "cart-confirmation-paypal-uid",
    path: "/cart/confirmation/paypal/:uid()",
    meta: _91uid_93zDNM9jcw7wMeta || {},
    component: () => import("/app/pages/cart/confirmation/paypal/[uid].vue").then(m => m.default || m)
  },
  {
    name: "cart",
    path: "/cart",
    meta: indexAacQV8oPDMMeta || {},
    component: () => import("/app/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "cart-login",
    path: "/cart/login",
    meta: logineBjEt6PfwAMeta || {},
    component: () => import("/app/pages/cart/login.vue").then(m => m.default || m)
  },
  {
    name: "cart-payment_complete",
    path: "/cart/payment_complete",
    component: () => import("/app/pages/cart/payment_complete.vue").then(m => m.default || m)
  },
  {
    name: "category-slug",
    path: "/category/:slug()",
    meta: _91slug_934s7wUcyk9UMeta || {},
    component: () => import("/app/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "category-best-deals-slug",
    path: "/category/best-deals/:slug()",
    meta: _91slug_93wgHwBr2tgwMeta || {},
    component: () => import("/app/pages/category/best-deals/[slug].vue").then(m => m.default || m)
  },
  {
    name: "category-best-deals",
    path: "/category/best-deals",
    meta: indexTNUPTnasHkMeta || {},
    component: () => import("/app/pages/category/best-deals/index.vue").then(m => m.default || m)
  },
  {
    name: "category-best-sellers-slug",
    path: "/category/best-sellers/:slug()",
    meta: _91slug_93J1ES9ES4EiMeta || {},
    component: () => import("/app/pages/category/best-sellers/[slug].vue").then(m => m.default || m)
  },
  {
    name: "category-best-sellers",
    path: "/category/best-sellers",
    meta: indexftpEyktn3sMeta || {},
    component: () => import("/app/pages/category/best-sellers/index.vue").then(m => m.default || m)
  },
  {
    name: "category-brand-slug",
    path: "/category/brand/:slug()",
    meta: _91slug_93e51aXsP7LeMeta || {},
    component: () => import("/app/pages/category/brand/[slug].vue").then(m => m.default || m)
  },
  {
    name: "category-green-everyday",
    path: "/category/green-everyday",
    meta: indexihCrXpRg16Meta || {},
    component: () => import("/app/pages/category/green-everyday/index.vue").then(m => m.default || m)
  },
  {
    name: "category-new-products-slug",
    path: "/category/new-products/:slug()",
    meta: _91slug_93uBFA3WwjJgMeta || {},
    component: () => import("/app/pages/category/new-products/[slug].vue").then(m => m.default || m)
  },
  {
    name: "category-new-products",
    path: "/category/new-products",
    meta: indexcmMufNXU4EMeta || {},
    component: () => import("/app/pages/category/new-products/index.vue").then(m => m.default || m)
  },
  {
    name: "category-rooms",
    path: "/category/rooms",
    meta: indexL6RgyuwLfGMeta || {},
    component: () => import("/app/pages/category/rooms/index.vue").then(m => m.default || m)
  },
  {
    name: "category-seasonal-selections",
    path: "/category/seasonal-selections",
    meta: indexnbgCEbdYY4Meta || {},
    component: () => import("/app/pages/category/seasonal-selections/index.vue").then(m => m.default || m)
  },
  {
    name: "category-special-discounts",
    path: "/category/special-discounts",
    meta: indexXasG3ROtP8Meta || {},
    component: () => import("/app/pages/category/special-discounts/index.vue").then(m => m.default || m)
  },
  {
    name: "cgu",
    path: "/cgu",
    meta: cguHWppEe3fB9Meta || {},
    component: () => import("/app/pages/cgu.vue").then(m => m.default || m)
  },
  {
    name: "cguMobile",
    path: "/cguMobile",
    meta: cguMobileQonVbgA1udMeta || {},
    component: () => import("/app/pages/cguMobile.vue").then(m => m.default || m)
  },
  {
    name: "cgv",
    path: "/cgv",
    meta: cgvqMKWudsQH7Meta || {},
    component: () => import("/app/pages/cgv.vue").then(m => m.default || m)
  },
  {
    name: "cgvMobile",
    path: "/cgvMobile",
    meta: cgvMobileOq2RhxLqFNMeta || {},
    component: () => import("/app/pages/cgvMobile.vue").then(m => m.default || m)
  },
  {
    name: "club-domain",
    path: "/club-domain",
    meta: indexmtumf81DIiMeta || {},
    component: () => import("/app/pages/club-domain/index.vue").then(m => m.default || m)
  },
  {
    name: "coming-soon",
    path: "/coming-soon",
    meta: indexhgvuRRuzCOMeta || {},
    component: () => import("/app/pages/coming-soon/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexhg2nWJrbRRMeta || {},
    component: () => import("/app/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "download-monthly-report-token",
    path: "/download/monthly-report/:token()",
    meta: _91token_93Q8bFOYrUvmMeta || {},
    component: () => import("/app/pages/download/monthly-report/[token].vue").then(m => m.default || m)
  },
  {
    name: "download-white-book-token",
    path: "/download/white-book/:token()",
    meta: _91token_93qUPfi3IVFcMeta || {},
    component: () => import("/app/pages/download/white-book/[token].vue").then(m => m.default || m)
  },
  {
    name: "login-activation",
    path: "/login/activation",
    meta: indexBYQ6pC1gbeMeta || {},
    component: () => import("/app/pages/login/activation/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: indexgK5VicK7y2Meta || {},
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login-set-password",
    path: "/login/set-password",
    meta: indexEdWVEiPDzoMeta || {},
    component: () => import("/app/pages/login/set-password/index.vue").then(m => m.default || m)
  },
  {
    name: "merchant-slug-slugCategory",
    path: "/merchant/:slug()/:slugCategory()",
    meta: _91slugCategory_93vuPVBjHgtvMeta || {},
    component: () => import("/app/pages/merchant/[slug]/[slugCategory].vue").then(m => m.default || m)
  },
  {
    name: "merchant-slug-about-us",
    path: "/merchant/:slug()/about-us",
    meta: indexcnqfG2VYxUMeta || {},
    component: () => import("/app/pages/merchant/[slug]/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: "merchant-slug-home",
    path: "/merchant/:slug()/home",
    meta: indexqzxBxLC014Meta || {},
    component: () => import("/app/pages/merchant/[slug]/home/index.vue").then(m => m.default || m)
  },
  {
    name: "merchant-slug",
    path: "/merchant/:slug()",
    meta: indexNmxixrLNStMeta || {},
    component: () => import("/app/pages/merchant/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "merchant-slug-products",
    path: "/merchant/:slug()/products",
    meta: productsU0iQHpOwjsMeta || {},
    component: () => import("/app/pages/merchant/[slug]/products.vue").then(m => m.default || m)
  },
  {
    name: "merchant-slug-search-keywords",
    path: "/merchant/:slug()/search/:keywords()",
    meta: _91keywords_93wlwrGmnC0qMeta || {},
    component: () => import("/app/pages/merchant/[slug]/search/[keywords].vue").then(m => m.default || m)
  },
  {
    name: "mission",
    path: "/mission",
    meta: missionTOO9rz5BZdMeta || {},
    component: () => import("/app/pages/mission.vue").then(m => m.default || m)
  },
  {
    name: "my-account-orders-uid",
    path: "/my-account/orders/:uid()",
    meta: _91uid_93ZPLIH5HlDXMeta || {},
    component: () => import("/app/pages/my-account/orders/[uid].vue").then(m => m.default || m)
  },
  {
    name: "my-account-orders",
    path: "/my-account/orders",
    meta: indexg0spLlUUW1Meta || {},
    component: () => import("/app/pages/my-account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-quotes-sales-uid",
    path: "/my-account/quotes-sales/:uid()",
    meta: _91uid_932CEmWkynyEMeta || {},
    component: () => import("/app/pages/my-account/quotes-sales/[uid].vue").then(m => m.default || m)
  },
  {
    name: "my-account-quotes-sales",
    path: "/my-account/quotes-sales",
    meta: indexuRp838sHoZMeta || {},
    component: () => import("/app/pages/my-account/quotes-sales/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-quotes-uid",
    path: "/my-account/quotes/:uid()",
    meta: _91uid_93Kaj3s2Ea63Meta || {},
    component: () => import("/app/pages/my-account/quotes/[uid].vue").then(m => m.default || m)
  },
  {
    name: "my-account-quotes",
    path: "/my-account/quotes",
    meta: indexuDpG6CuNOvMeta || {},
    component: () => import("/app/pages/my-account/quotes/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-sales-uid",
    path: "/my-account/sales/:uid()",
    meta: _91uid_93i9Ltw0WlGnMeta || {},
    component: () => import("/app/pages/my-account/sales/[uid].vue").then(m => m.default || m)
  },
  {
    name: "my-account-sales",
    path: "/my-account/sales",
    meta: indexK0UD6KfaxYMeta || {},
    component: () => import("/app/pages/my-account/sales/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-sales-issues-uid",
    path: "/my-account/sales/issues/:uid()",
    meta: _91uid_93bw0lGuoLfyMeta || {},
    component: () => import("/app/pages/my-account/sales/issues/[uid].vue").then(m => m.default || m)
  },
  {
    name: "press-article",
    path: "/press/:article()",
    meta: _91article_93qHxrU9j9CMMeta || {},
    component: () => import("/app/pages/press/[article].vue").then(m => m.default || m)
  },
  {
    name: "press",
    path: "/press",
    meta: indexpFMFCyrWRdMeta || {},
    component: () => import("/app/pages/press/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyg3gwBRGXatMeta || {},
    component: () => import("/app/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacyMobile",
    path: "/privacyMobile",
    meta: privacyMobileQRsdlb5UqwMeta || {},
    component: () => import("/app/pages/privacyMobile.vue").then(m => m.default || m)
  },
  {
    name: "pro-domain-contact",
    path: "/pro-domain/contact",
    meta: indexYaOlSid8nXMeta || {},
    component: () => import("/app/pages/pro-domain/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "pro-domain",
    path: "/pro-domain",
    meta: index7SZPfOa7hjMeta || {},
    component: () => import("/app/pages/pro-domain/index.vue").then(m => m.default || m)
  },
  {
    name: "pro-domain-offers-slug",
    path: "/pro-domain/offers/:slug()",
    meta: _91slug_93OeRp0BK269Meta || {},
    component: () => import("/app/pages/pro-domain/offers/[slug].vue").then(m => m.default || m)
  },
  {
    name: "pro-domain-services-slug",
    path: "/pro-domain/services/:slug()",
    meta: _91slug_93ESdJN2DAltMeta || {},
    component: () => import("/app/pages/pro-domain/services/[slug].vue").then(m => m.default || m)
  },
  {
    name: "pro-domain-tarifs",
    path: "/pro-domain/tarifs",
    meta: indexFpixClkMa4Meta || {},
    component: () => import("/app/pages/pro-domain/tarifs/index.vue").then(m => m.default || m)
  },
  {
    name: "product-slug",
    path: "/product/:slug()",
    meta: _91slug_93FPU7I0zot8Meta || {},
    component: () => import("/app/pages/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: "products",
    path: "/products",
    meta: index18IXo8oN8QMeta || {},
    component: () => import("/app/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "qrcode",
    path: "/qrcode",
    meta: qrcodeOOn1CFOUWvMeta || {},
    component: () => import("/app/pages/qrcode.vue").then(m => m.default || m)
  },
  {
    name: "root-domain-download-premium",
    path: "/root-domain/download-premium",
    meta: download_45premiumu9x2VpkD2WMeta || {},
    component: () => import("/app/pages/root-domain/download-premium.vue").then(m => m.default || m)
  },
  {
    name: "root-domain-download",
    path: "/root-domain/download",
    meta: downloadYELOg7MyZCMeta || {},
    component: () => import("/app/pages/root-domain/download.vue").then(m => m.default || m)
  },
  {
    name: "root-domain",
    path: "/root-domain",
    meta: index2FtfahU8hnMeta || {},
    component: () => import("/app/pages/root-domain/index.vue").then(m => m.default || m)
  },
  {
    name: "root-domain-landing-slug",
    path: "/root-domain/landing/:slug()",
    meta: _91slug_93WJ5cQrUxL2Meta || {},
    component: () => import("/app/pages/root-domain/landing/[slug].vue").then(m => m.default || m)
  },
  {
    name: "root-domain-landing-boost-my-sales",
    path: "/root-domain/landing/boost-my-sales",
    meta: boost_45my_45salesKePKmfnb3rMeta || {},
    component: () => import("/app/pages/root-domain/landing/boost-my-sales.vue").then(m => m.default || m)
  },
  {
    name: "root-domain-landing",
    path: "/root-domain/landing",
    meta: indexzKginzBMFmMeta || {},
    component: () => import("/app/pages/root-domain/landing/index.vue").then(m => m.default || m)
  },
  {
    name: "root-domain-my-account-mkp-alerts-uid-unsubscribe-email-email",
    path: "/root-domain/my-account/mkp-alerts/:uid()/unsubscribe/email/:email()",
    meta: _91email_93h4aoY6XJu5Meta || {},
    component: () => import("/app/pages/root-domain/my-account/mkp-alerts/[uid]/unsubscribe/email/[email].vue").then(m => m.default || m)
  },
  {
    name: "root-domain-my-account-mkp-alerts-uid-unsubscribe",
    path: "/root-domain/my-account/mkp-alerts/:uid()/unsubscribe",
    meta: indexWOuS1vFrOTMeta || {},
    component: () => import("/app/pages/root-domain/my-account/mkp-alerts/[uid]/unsubscribe/index.vue").then(m => m.default || m)
  },
  {
    name: "root-domain-my-account-mkp-alerts",
    path: "/root-domain/my-account/mkp-alerts",
    meta: indexdv1xLUaweKMeta || {},
    component: () => import("/app/pages/root-domain/my-account/mkp-alerts/index.vue").then(m => m.default || m)
  },
  {
    name: "root-domain-my-account-onboarding",
    path: "/root-domain/my-account/onboarding",
    meta: indext3zoVQZkQLMeta || {},
    component: () => import("/app/pages/root-domain/my-account/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: "root-domain-partner-partner",
    path: "/root-domain/partner/:partner()",
    meta: _91partner_93I7GK12qxzqMeta || {},
    component: () => import("/app/pages/root-domain/partner/[partner].vue").then(m => m.default || m)
  },
  {
    name: "root-domain-stock",
    path: "/root-domain/stock",
    meta: indexTzCAr4MADkMeta || {},
    component: () => import("/app/pages/root-domain/stock/index.vue").then(m => m.default || m)
  },
  {
    name: "root-domain-subscription",
    path: "/root-domain/subscription",
    meta: subscriptiontxHJK8ZmAXMeta || {},
    component: () => import("/app/pages/root-domain/subscription.vue").then(m => m.default || m)
  },
  {
    name: "search-keywords",
    path: "/search/:keywords()",
    meta: _91keywords_93SEAtR1bDafMeta || {},
    component: () => import("/app/pages/search/[keywords].vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    meta: indexxLsScIFze3Meta || {},
    component: () => import("/app/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/sitemap-fr.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/sitemap-be.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/sitemap-nl.xml",
    component: component_45stubnO9MU04yTU
  }
]