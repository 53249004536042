import Repository from './repository'

function getFilters(filters, config) {
  const brandsFilter = filters.reduce((filtered, filter) => {
    if (filter.type === 'BRAND') {
      filtered.push(filter.value)
    }
    return filtered
  }, [])
  if (brandsFilter.length > 0) config.params['search[brand_uids]'] = brandsFilter.join()

  const bookedByFilter = filters.reduce((filtered, filter) => {
    if (filter.type === 'BOOKED_BY') {
      filtered.push(filter.value)
    }
    return filtered
  }, [])
  if (bookedByFilter.length > 0) config.params['search[booked_by]'] = bookedByFilter.join()

  const categoriesFilter = filters.reduce((filtered, filter) => {
    if (filter.type === 'CATEGORY') {
      filtered.push(filter.value)
    }
    return filtered
  }, [])
  if (categoriesFilter.length > 0) {
    config.params['search[parent_category_uids]'] = categoriesFilter.join()
  }

  const storagesFilter = filters.reduce((filtered, filter) => {
    if (filter.type === 'STORAGE') {
      filtered.push(filter.value)
    }
    return filtered
  }, [])
  if (storagesFilter.length > 0) {
    config.params['search[parent_storage_uids]'] = storagesFilter.join()
  }

  const hasAlertFilter = filters.find(f => f.type === 'HAS_ALERT')
  if (hasAlertFilter) config.params['search[has_alert]'] = true

  const isPublicFilter = filters.find(f => f.type === 'IS_PUBLIC')
  if (isPublicFilter) config.params['search[is_public]'] = true

  const sortByFilter = filters.find(f => f.type === 'SORT_BY')
  if (sortByFilter) config.params.sort_by = sortByFilter.value

  const sortDirectionFilter = filters.find(f => f.type === 'SORT_DIRECTION')
  if (sortDirectionFilter) config.params.sort_direction = sortDirectionFilter.value

  const queryFilter = filters.find(f => f.type === 'QUERY')
  if (queryFilter) config.params['search[prefix_name]'] = queryFilter.value
  return config
}

export default class StockRepository extends Repository {
  create(companyUid, stock, mapping = this.mapper.stockMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/stocks`
    let config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.post(url, { stock }, config).then(response => {
      return response.stock
    })
  }

  update(companyUid, stock, mapping = this.mapper.stockMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/stocks/${stock.uid}`
    let config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.put(url, { stock }, config).then(response => {
      return response.stock
    })
  }

  multiUpdate(companyUid, payload, mapping = this.mapper.searchMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/stocks/updates`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }

    return this.fetcher.post(url, payload, config).then(response => ({
      stocks: response.stocks.filter(el => el.success).map(el => el.stock),
      multi: true,
      errors: response.stocks.filter(el => !el.success),
    }))
  }

  delete(companyUid, stocks, mapping = this.mapper.stockMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/stocks/archives`
    let config = {
      headers: {
        Mapping: mapping,
      },
    }
    const payload = stocks
    return this.fetcher.delete(url, config, payload).then(response => ({
      stocks: response.stocks.filter(el => el.success).map(el => el.stock),
      multi: true,
      errors: response.stocks.filter(el => !el.success),
    }))
  }

  search(companyUid, perPage, page, filters = null, mapping = this.mapper.searchMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/stocks`
    let config = {
      params: {
        per_page: perPage,
        page,
      },
      headers: {
        Mapping: mapping,
      },
    }

    if (filters && filters.length > 0) {
      let computedFilters = getFilters(filters, config)
      config = { ...config, ...computedFilters }
    }

    return this.fetcher.get(url, config).then(response => ({
      stocks: response.stocks,
      total: parseInt(response.total, 10),
    }))
  }

  book(companyUid, stockUid, payload, mapping = this.mapper.searchMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/stocks/${stockUid}/book`
    const params = {
      method: payload.selected_quantity > 0 ? 'PUT' : 'DELETE',
      url,
      data: {
        stock: payload,
      },
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.customFetch(params).then(response => ({ stocks: [response.stock], multi: false }))
  }

  multiBook(companyUid, payload, mapping = this.mapper.searchMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/stocks/books`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.post(url, payload, config).then(response => ({
      stocks: response.stocks.filter(el => el.success).map(el => el.stock),
      multi: true,
      errors: response.stocks.filter(el => !el.success),
    }))
  }

  multiUnbook(companyUid, stocks, mapping = this.mapper.searchMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/stocks/unbooks`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    const payload = stocks
    return this.fetcher.delete(url, config, payload).then(response => ({
      stocks: response.stocks.filter(el => el.success).map(el => el.stock),
      multi: true,
      errors: response.stocks.filter(el => !el.success),
    }))
  }

  sale(companyUid, stockUid, payload, mapping = this.mapper.searchMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/stocks/${stockUid}`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    let remove = payload === null || payload === undefined
    const data = {
      stock: {
        is_public: !remove,
        selling_quantity: remove ? 'by_unit' : payload.selling_quantity,
        price: remove ? null : payload.price,
        selected_quantity: remove ? null : payload.selected_quantity,
      },
    }
    return this.fetcher.put(url, data, config).then(response => ({ stocks: [response.stock], multi: false }))
  }

  stockManagement(companyUid, payload, mapping = this.mapper.searchMapping) {
    const url = `${this.backendUrlV2}companies/${companyUid}/stocks/sales_management`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.post(url, payload, config).then(response => ({
      stocks: response.stocks.filter(el => el.success).map(el => el.stock),
      multi: true,
      errors: response.stocks.filter(el => !el.success),
    }))
  }

  multiSale(companyUid, payload, mapping = this.mapper.searchMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/stocks/put_up_for_sale`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.post(url, payload, config).then(response => ({
      stocks: response.stocks.filter(el => el.success).map(el => el.stock),
      multi: true,
      errors: response.stocks.filter(el => !el.success),
    }))
  }

  multiRemoveFromSale(companyUid, stocks, mapping = this.mapper.searchMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/stocks/withdraw_from_sale`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    const payload = stocks
    return this.fetcher.delete(url, config, payload).then(response => ({
      stocks: response.stocks.filter(el => el.success).map(el => el.stock),
      multi: true,
      errors: response.stocks.filter(el => !el.success),
    }))
  }

  export(companyUid, filters, mapping = this.mapper.stockMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/stocks/download`
    let config = {
      params: {},
      headers: {
        Mapping: mapping,
      },
    }
    if (filters && filters.length > 0) {
      let computedFilters = getFilters(filters, config)
      config = { ...config, ...computedFilters }
    }
    return this.fetcher.get(url, config).then(response => response)
  }
  getCategoriesTree(companyUid) {
    const url = `${this.backendUrlV2}im/web/companies/${companyUid}/categories`
    let config = {
      params: {},
    }
    return this.fetcher.get(url, config).then(response => response)
  }
}
