import { useCountries } from '@/composables/useCountries'
import { useCurrentLocale } from '@/composables/useCurrentLocale'
import isoNameCountries from '@/data/iso-name-countries'
import locales from '@/lang/locales'
import { useI18nStore } from '@/stores/i18nStore'

export default {
  setup() {
    // TODO defaultHeadMeta fix head()
    const defaultHeadMeta = []
    return {
      defaultHeadMeta,
    }
  },
  computed: {
    countries() {
      const { countries } = useCountries()
      return countries
    },
    locales() {
      return locales
    },
    isoNameCountries() {
      return isoNameCountries
    },
    tld() {
      const { tld } = useCurrentLocale()
      return tld
    },
    locale() {
      const { locale } = useCurrentLocale()
      return locale
    },
    country() {
      const { country } = useCurrentLocale()
      return country
    },
    langCode() {
      // return fr-FR // useful for ex. for trustpilot
      return this.locale + '-' + this.tld
    },
    hostCountryName() {
      // return France, Belgique
      return isoNameCountries[this.tld]
    },
    availableLanguages() {
      // return array of available languages like ['fr', 'be']
      return this.country?.availableLanguages?.map(lang => {
        return locales.find(locale => locale?.code?.toLowerCase() === lang?.toLowerCase())
      })
    },
    localCompanyCode() {
      // return SIREN, KVK or BCE
      return this.country?.localCompanyCode
    },
    countryPhoneNumber() {
      return this.country?.phoneNumber
    },
    contactFormLink() {
      return this.country?.contactFormLink[this.locale]
    },
  },
  mounted() {
    if (this.$route.query?.location_modal_disabled == 1) {
      const locationModalDisabled = useCookie('x-location_modal_disabled')
      locationModalDisabled.value = '1'
    }
  },
  methods: {
    async switchLocale(lang, fromUserAction = false) {
      const i18nStore = useI18nStore()
      this.$i18n.setLocale(lang)
      const langCookie = useCookie('x-lang', { maxAge: 60 * 60 * 24 * 365, domain: `.${this.country?.host}` })
      langCookie.value = lang
      this.afterSwitchLocale()
      this.opened = false
      i18nStore.locale = lang
      let langCode = new Set()
      langCode.add(this.tld?.toLowerCase())
      langCode.add(lang?.toLowerCase())
      langCode = Array.from(langCode)
      langCode = langCode?.join('-')
      if (fromUserAction) {
        let alternates = document?.querySelectorAll('link[rel=alternate]')
        let alternate = null
        alternates?.forEach(link => {
          if (link?.hreflang?.toLowerCase() === langCode) {
            alternate = link
          }
        })
        if (alternate) {
          window.location.replace(alternate.href)
        }
      }
      refreshNuxtData()
      if (import.meta.client) {
        let html = document.querySelector('html')
        html.lang = langCode
      }
    },
    afterSwitchLocale() {
      // can be define in component after locale switching
      return null
    },
    getCountry(value) {
      // return plain country depending on value
      return this.countries?.find(
        x => x?.name === value || x?.name?.includes(name) || x?.iso === value || x?.code === value
      )
    },
  },
}
