<template>
  <div
    v-if="backgroundImage || !loaded"
    ref="refImage"
    :src="imageError ? src : urlWithoutProvider"
    :class="[classVariant, isLazy ? 'lazy-load' : '', backgroundImage ? 'background-image' : '']"
    :style="style"
    :alt="alt"
    v-bind="$attrs"
  >
    <slot />
  </div>
  <NuxtImg
    v-else
    ref="refImage"
    :src="imageError ? src : urlWithoutProvider"
    :width="width"
    :height="height"
    :quality="quality"
    :provider="imageError ? undefined : provider"
    :fetchpriority="isLazy ? 'low' : 'high'"
    :class="[classVariant, isLazy ? 'lazy-load' : '', backgroundImage ? 'background-image' : '']"
    :style="style"
    :alt="alt"
    :options="options"
    format="webp"
    :loading="isLazy ? 'lazy' : 'eager'"
    v-bind="$attrs"
    @loaded="onLoaded"
    @error="onError"
  >
    <slot />
  </NuxtImg>
</template>

<script setup>
import { useIntersectionStore } from '@/stores/intersection'
// by default lazy parameter is true
// declare provide() { return { lazy: false } } in any parent/upper-parent to force lazy parameter
// or declare in props lazyLoading
// in logic the props weight is higher than provide()

const lazy = inject('lazy', true)

// const nuxtImage = resolveComponent('NuxtImg')

const props = defineProps({
  src: { type: String, required: true },
  alt: { type: String, default: '' },
  backgroundImage: { type: Boolean, default: false },
  width: { type: [String, Number], default: null },
  maxWidth: { type: [String, Number], default: null },
  minWidth: { type: [String, Number], default: null },
  height: { type: [String, Number], default: null },
  quality: { type: Number, default: 80 },
  classVariant: { type: null, default: null },
  bgSize: { type: String, default: 'cover' },
  bgMinSize: { type: Boolean, default: false },
  lazyLoading: { type: Boolean, default: null },
})

const emit = defineEmits(['loaded', 'error'])

const refImage = ref(null)
const loaded = ref(false)
const intersectionStore = useIntersectionStore()
const options = ref({ transformations: { fetchFormat: 'auto', quality: 'auto' } })
const imageError = ref(null)
const isLazy = computed(() => {
  return props.lazyLoading !== null ? props.lazyLoading : lazy
})

const provider = computed(() => {
  const cloudfrontRegex =
    /(cloudfront\.net|s3-eu-west-3\.amazonaws\.com\/stockpro-references|s3-eu-west-3\.amazonaws\.com\/stockpro-marketing|s3-eu-west-3\.amazonaws\.com\/stockpro-webapp)/gi
  if (props.src?.includes('storyblok')) {
    return 'storyblok'
  } else if (props.src?.includes('cloudinary.com/hr363qyfe')) {
    return 'cloudinary'
  } else if (cloudfrontRegex.test(props.src)) {
    return 'cloudfront'
  }
  return undefined
})

const urlWithoutProvider = computed(() => {
  let url = props.src
  if (provider.value === 'storyblok') {
    url = props.src?.replace('https://a.storyblok.com', '')
  } else if (provider.value === 'cloudinary') {
    url = props.src?.replace('https://res.cloudinary.com/hr363qyfe/image/upload/', '')
  } else if (provider.value === 'cloudfront') {
    url = props.src?.replace('https://d2tbovyd0ghy3r.cloudfront.net', '')
  }
  return url
})

const urlWithProvider = computed(() => {
  const img = useImage()

  if (urlWithoutProvider.value) {
    return img(
      urlWithoutProvider.value,
      {
        width: props.width,
        height: props.height,
      },
      {
        provider: provider.value,
      }
    )
  }
  return props.src
})

if (!isLazy.value) {
  useHead({
    link: [{ rel: 'preload', href: urlWithProvider.value, as: 'image' }],
  })
}
// Reduce CLS for img solutions pro in homepage
const preSizeCLS = computed(() => {
  const url = props.src
  if (provider.value === 'storyblok') {
    const regex = /\/(\d+)x(\d+)\//
    const matches = url.match(regex)
    const dimensions = {
      //width: matches[1],
      height: matches[2],
    }
    return dimensions
  }
  return null
})
const cls = computed(() => {
  const instance = getCurrentInstance()
  return instance.parent.cls
})

const style = ref({
  height:
    !loaded.value && cls.value && preSizeCLS.value?.height ? preSizeCLS.value?.height + 'px' : props.height + 'px',
})

onMounted(() => {
  if (isLazy.value) {
    // Init observer if not already done
    intersectionStore.initObserver()
    intersectionStore.observe(refImage.value, entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('lazy-load--loaded')
        loaded.value = true
        if (props.backgroundImage && urlWithoutProvider.value) {
          setBackground()
        }
        intersectionStore.unobserve(refImage.value)
        // console.log('unobserve image')
      } else {
        loaded.value = false
      }
    })
  } else {
    loaded.value = true
    if (props.backgroundImage && urlWithoutProvider.value) {
      setBackground()
    }
  }
})
onUnmounted(() => {
  if (isLazy.value) {
    intersectionStore.unobserve(refImage.value)
  }
})

const setBackground = () => {
  const img = useImage()

  const imgUrl = img(
    // Make preprod imgs work
    urlWithoutProvider.value.replace('/preprod/', '/production/'),
    {
      width: props.width,
      height: props.height,
    },
    {
      provider: provider.value,
    }
  )
  style.value.height = null
  style.value = `
            background-image: url(${imgUrl});
            ${
              props.width
                ? props.bgMinSize
                  ? `width: min(${!/[a-z%]/.test(props.width) ? `${props.width}px` : props.width}, 100%);`
                  : `width: ${!/[a-z%]/.test(props.width) ? `${props.width}px` : props.width};`
                : ''
            }
            ${
              props.height
                ? props.bgMinSize
                  ? `height: min(${!/[a-z%]/.test(props.height) ? `${props.height}px` : props.height}, 100%);`
                  : `height: ${!/[a-z%]/.test(props.height) ? `${props.height}px` : props.height};`
                : ''
            }
            ${props.bgSize ? `background-size: ${props.bgSize};` : ''}
            ${
              props.maxWidth
                ? `max-width: ${!/[a-z%]/.test(props.maxWidth) ? `${props.maxWidth}px` : props.maxWidth};`
                : ''
            }
            ${
              props.minWidth
                ? `min-width: ${!/[a-z%]/.test(props.minWidth) ? `${props.minWidth}px` : props.minWidth};`
                : ''
            }
          `
}
const onLoaded = () => {
  imageError.value = null
  emit('loaded')
}
const onError = () => {
  if (!imageError.value) {
    imageError.value = Date.now()
  }
  emit('error')
}
</script>

<style scoped lang="scss">
.background-image {
  background-position: center;
  background-repeat: no-repeat;
}
.lazy-load {
  position: relative;
  overflow: hidden;
}

.lazy-load--loaded {
  visibility: visible !important;
  opacity: 1 !important;
  transition:
    visibility 0s linear 0.33s,
    opacity 0.33s linear;
}
</style>
