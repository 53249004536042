export function useCompany(companyData = {}) {
  const company = {
    ...companyData,
    hasPickup: companyData.has_pick_up,
    hasDelivery: companyData.has_delivery,
    deliveryMethods: companyData.delivery_methods,
  }

  const deliveryFee = () => {
    if (company.has_delivery) {
      // Get first delivery method that is not pick up
      const deliveryMethod = company.delivery_methods?.find(r => r !== 'PICK_UP')
      // Use amount only for SELF_SHIPPING
      return deliveryMethod === 'SELF_SHIPPING' ? Number(company.delivery_fee) : null
    }
    return null
  }

  const offersDeliveryFreeOfCharge = () => {
    return company.delivery_free_of_charge_vat > 0
  }

  const deliveryFreeOfCharge = (vat = true) => {
    return vat ? Number(company.delivery_free_of_charge_vat) : Number(company.delivery_free_of_charge)
  }

  return {
    ...company,
    deliveryFee: deliveryFee(),
    offersDeliveryFreeOfCharge: offersDeliveryFreeOfCharge(),
    deliveryFreeOfCharge,
  }
}
