import { useClubStore } from '@/stores/club'

const routeOption = (route, key, value) => {
  return route.matched?.some(m => {
    if (m.meta[key] === value) {
      return true
    } else if (import.meta.client && m.components && Array.isArray(m.components)) {
      // Client
      return Object.values(m.components)?.some(component => component.options && component.options[key] === value)
    } else if (m.components && Array.isArray(m.components)) {
      // SSR
      return Object.values(m.components)?.some(component =>
        Object.values(component._Ctor)?.some(ctor => ctor.options && ctor.options[key] === value)
      )
    }
  })
}

export default defineNuxtRouteMiddleware(to => {
  const config = useRuntimeConfig()
  if (config.public.NAVIGATION_PAGE_LOG) {
    const { userAgent } = useDevice()
    const { country } = useCurrentLocale()
    console.log('country', country?.code, '- path', to.fullPath, '- user-agent', userAgent)
  }
  const clubStore = useClubStore()
  const { status } = useAuth()
  const router = useRouter()

  if (clubStore.isClub && routeOption(to, 'authClub', false)) {
    return
  }

  // Disable middleware if options: { auth: false } is set on the route and not in a club
  if (!clubStore.isClub && routeOption(to, 'auth', false)) {
    return
  }
  if (!router.resolve(to.path).name) {
    console.log('page not found', to.path)
    throw createError({ statusCode: 404, message: `Page not found : ${to.path}` })
  }
  if (to.path !== '/login') {
    const redirectUrl = '/login?redirect=' + to.path
    if (status?.value === 'unauthenticated') {
      return navigateTo(redirectUrl)
    }
  }
})
