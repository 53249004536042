// import ImageLazy from '@/components/ImageLazy'

const LazyComponent = {
  mounted(el, binding) {
    if (binding.value) {
      el.classList.add('hidden')

      const onIntersection = (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            el.classList.remove('hidden')
            observer.unobserve(el)
          }
        })
      }

      const options = {
        rootMargin: '0px',
        threshold: 0,
      }
      const observer = new IntersectionObserver(onIntersection, options)
      observer.observe(el)

      el._observer = observer
    }
  },
  unmounted(el) {
    if (el._observer) {
      el._observer.disconnect()
      delete el._observer
    }
  },
  getSSRProps(binding) {
    return {
      id: binding.value,
    }
  },
}

export default defineNuxtPlugin(ctx => {
  // ctx.vueApp.component('ImageLazy', ImageLazy)
  ctx.vueApp.directive('lazycomponent', LazyComponent)
})
