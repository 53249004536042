// import UserService from '@/services/UserService'
import { useUserStore } from '@/stores/user'
import { useCurrentLocale } from '@/composables/useCurrentLocale'

export default defineNuxtPlugin(async () => {
  const { status, setToken, token } = useAuthState()
  const { getSession } = useAuth()
  const { $api } = useNuxtApp()
  const { country } = useCurrentLocale()
  const authCookie = useCookie('auth.token', { maxAge: 60 * 60 * 24 * 6, domain: `.${country?.host}` })
  if (authCookie.value) {
    setToken(authCookie.value)
  }
  const isAuthenticated = status.value === 'authenticated' || token.value

  const userStore = useUserStore()
  if (isAuthenticated) {
    try {
      await getSession({ force: true })
      const user = await $api().user.getMe()
      userStore.setUser(user)
    } catch (e) {
      if (e?.response?.data?.message?.includes('401')) {
        userStore.logout()
      }
    }
  }
  return {
    provide: {
      currentUser: () => userStore,
    },
  }
})
