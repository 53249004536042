import truncate from 'lodash.truncate'
import { useClubStore } from '@/stores/club'
import { useConfigStore } from '@/stores/config'
import { useProAreaStore } from '@/stores/proArea'

export function useDefaultMetaData() {
  const { $i18n } = useNuxtApp()
  const route = useRoute()
  const config = useRuntimeConfig()

  const proAreaStore = useProAreaStore()
  const clubStore = useClubStore()
  const configStore = useConfigStore()

  const { country, locale } = useCurrentLocale()
  const { countries } = useCountries()

  const defaultTitle = computed(() => country?.metaTitle?.[locale])
  const defaultDescription = computed(() => country?.metaDescription?.[locale])

  const pageData = configStore.getPageData

  const reference = ref({})
  const category = ref({})
  const brand = ref({})
  const merchant = ref({})

  const dynamicMeta = ref({})
  const customKey = ref(null)
  const customTarget = ref(null)
  const customScript = ref([])
  const noIndex = ref(false)

  const query = import.meta.client
    ? window?.location?.search?.replace('?', '')
    : Object.keys(route?.query || {})
        .map(key => `${key}=${route.query[key]}`)
        .join('&')

  const currentPage =
    query
      ?.split('&')
      .find(param => param.startsWith('page='))
      ?.split('=')[1] || null
  const langParam =
    query
      ?.split('&')
      .find(param => param.startsWith('lang='))
      ?.split('=')[1] || null
  const getCurrentUrl = () => {
    const path = import.meta.client ? window?.location?.pathname : route?.path || ''
    return `https://www.${country.host}${path}${currentPage > 1 || langParam ? `?${query}` : ''}`
  }

  const getHrefLang = () => {
    // get TLD and PATH
    const getTranslatedPath = lang => {
      const path = import.meta.client ? window?.location?.pathname : route?.path || ''
      const previousSlug = pageData?.slug
      const newSlug = pageData?.slugs?.[lang]
      return previousSlug && newSlug ? path.replace(previousSlug, newSlug) : path
    }

    const _hreflang = []

    const path = import.meta.client ? window?.location?.pathname : route?.path || ''

    const translatedSlugs = ['/category/', '/product/']

    const excludeUrls = ['/blog/', '/press/']

    const isProductPage = path.includes('/product/')

    const isCategoryPage =
      /^\/category\/(?!brand|best-deals|best-sellers|green-everyday|new-products|rooms|seasonal-selections|special-discounts)/.test(
        path
      )
    const isBrandPage = path.includes('/brand/')
    const isMerchantPage = path.includes('/merchant/')

    function referenceHasProductsInCountry(countrySelected) {
      if (reference?.value?.public_stock_countries?.length) {
        return reference?.value?.public_stock_countries?.find(x => x === countrySelected.code)
      }
      return true
    }

    function categoryHasProductsInCountry(countrySelected) {
      if (
        category?.value?.stockAvailable?.[countrySelected.code] !== null ||
        category?.value?.stockAvailable?.[countrySelected.code] !== undefined
      ) {
        return category?.value?.stockAvailable?.[countrySelected.code] > 0
      }
      return true
    }

    function brandHasProductsInCountry(countrySelected) {
      if (
        brand.value?.offersCountByCountry?.[countrySelected.code] !== null ||
        brand.value?.offersCountByCountry?.[countrySelected.code] !== undefined
      ) {
        return brand.value?.offersCountByCountry?.[countrySelected.code] > 0
      }
      return true
    }

    function merchantHasProductsInCountry(countrySelected) {
      if (
        merchant.value?.offers_count_by_country?.[countrySelected.code] !== null ||
        merchant.value?.offers_count_by_country?.[countrySelected.code] !== undefined
      ) {
        return merchant.value?.offers_count_by_country?.[countrySelected.code] > 0
      }
      return true
    }

    function addHrefLang(countrySelected, lang, currentPath, pathLang = null) {
      if (
        (isProductPage && !referenceHasProductsInCountry(countrySelected)) ||
        (isCategoryPage && !categoryHasProductsInCountry(countrySelected)) ||
        (isBrandPage && !brandHasProductsInCountry(countrySelected)) ||
        (isMerchantPage && !merchantHasProductsInCountry(countrySelected))
      ) {
        return
      }
      _hreflang.push({
        key: `alternate-hreflang${lang ? `-${lang}` : ''}`,
        rel: 'alternate',
        hreflang: lang,
        href: `https://www.${countrySelected.host}${currentPath}${pathLang ? `?lang=${pathLang}` : ''}${
          currentPage > 1 ? `${pathLang ? '&' : '?'}${query}` : ''
        }`,
      })
    }

    // for each country, create an hreflang and handle multiples langs
    function getHrefLangForCountry(c) {
      if (c.availableLanguages?.length > 1) {
        for (const lang of c.availableLanguages) {
          const currentPath =
            lang !== locale && translatedSlugs.some(x => path.match(x)) ? getTranslatedPath(lang) : path
          addHrefLang(c, `${c.code}-${lang?.toUpperCase()}`, currentPath, lang)
        }
      } else {
        const currentPath = getTranslatedPath(c.availableLanguages[0])
        addHrefLang(c, c.code, currentPath)
      }
    }
    // define urls to exclude to generate an hreflang for other countries
    if (!excludeUrls.some(x => path.match(x))) {
      for (const c of countries) {
        getHrefLangForCountry(c)
      }
    } else {
      getHrefLangForCountry(country)
    }

    if (isProductPage && !reference?.value?.uid && config.public.NODE_ENV !== 'production') {
      console.log('reference is not defined')
    }
    if (isCategoryPage && !category?.value?.uid && config.public.NODE_ENV !== 'production') {
      console.log('category is not defined')
    }
    if (isBrandPage && !brand?.value?.uid && config.public.NODE_ENV !== 'production') {
      console.log('brand is not defined')
    }
    if (isMerchantPage && !merchant?.value?.uid && config.public.NODE_ENV !== 'production') {
      console.log('merchant is not defined')
    }
    return _hreflang
  }

  const getCanonical = () => {
    const path = import.meta.client ? window?.location?.pathname : route?.path || ''
    // create canonical
    const canonical = {
      key: 'canonical',
      rel: 'canonical',
      href: `https://www.${country.host}${path}${currentPage > 1 || langParam ? `?${query}` : ''}`,
    }
    return canonical
  }

  const hreflang = computed(() => getHrefLang() || [])

  const canonical = computed(() => getCanonical() || [])

  const setTitle = () => {
    let title =
      dynamicMeta?.value?.title ??
      (customKey.value && $i18n.te(`meta.title.${customKey.value}`)
        ? $i18n.t(`meta.title.${customKey.value}`, {
            [customKey.value]: customTarget.value
              ? customTarget.value
              : clubStore.isClub
                ? truncate(clubStore.name, { length: 70 })
                : 'StockPro',
          })
        : null) ??
      defaultTitle.value
    if (!title?.includes(' | StockPro')) {
      title = `${title} | StockPro`
    }
    return title
  }

  const setDescription = () => {
    return (
      dynamicMeta?.value?.description ??
      (customKey.value && $i18n?.te(`meta.description.${customKey.value}`)
        ? $i18n?.t(`meta.description.${customKey.value}`, {
            [customKey.value]: truncate(customTarget.value, { length: 70 }),
          })
        : null) ??
      defaultDescription.value
    )
  }

  const defaultHead = () => {
    const { status } = useAuth()
    const title = setTitle()
    const description = setDescription()

    const {
      public: {
        NEW_RELIC_APPLICATION_ID,
        NEW_RELIC_LICENSE_KEY,
        NEW_RELIC_ACCOUNT_ID,
        NEW_RELIC_AGENT_ID,
        NEW_RELIC_TRUST_KEY,
      },
    } = useRuntimeConfig()

    const scripts = [
      ...customScript.value,
      {
        // Inject the New Relic config dynamically
        // This should come before the New Relic script itself
        hid: 'newrelic-config',
        innerHTML: `
            window.NREUM = window.NREUM || {};
            window.NREUM.info = {
              applicationID: "${NEW_RELIC_APPLICATION_ID}",
              licenseKey: "${NEW_RELIC_LICENSE_KEY}",
              beacon: "bam.eu01.nr-data.net",
              errorBeacon: "bam.eu01.nr-data.net",
            };
            window.NREUM.loader_config = {
              accountID: "${NEW_RELIC_ACCOUNT_ID}",
              agentID: "${NEW_RELIC_AGENT_ID}",
              applicationID: "${NEW_RELIC_APPLICATION_ID}",
              licenseKey: "${NEW_RELIC_LICENSE_KEY}",
              trustKey: "${NEW_RELIC_TRUST_KEY}",
              sa: 1,
            };
          `,
        type: 'text/javascript',
        charset: 'utf-8',
      },
      {
        hid: 'newrelic',
        src: '/newrelic.js',
        async: true,
        type: 'text/javascript',
        charset: 'utf-8',
      },
      {
        hid: 'probance',
        src: '//t4.my-probance.one/webtrax/rsc/stockpro.js',
        async: true,
        type: 'text/javascript',
        charset: 'utf-8',
      },
    ]
    if (status.value === 'authenticated' && country?.code === 'fr' && !clubStore.isClub) {
      scripts.push({
        hid: 'mcjs',
        innerHTML: `!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/49648c4b7d22f482da0ee9755/ea1440b5ec04d665666c7e22f.js");`,
        type: 'text/javascript',
        async: true,
        charset: 'utf-8',
      })
    }
    const meta = [{ name: 'facebook-domain-verification', content: config.public.FACEBOOK_VERIFICATION }]
    if (noIndex.value) {
      meta.push({ key: 'robots', hid: 'robots', name: 'robots', content: 'noindex' })
    }
    const links = [
      {
        key: 'icon',
        rel: 'icon',
        type: 'image/x-icon',
        href: clubStore.isClub ? clubStore.clubConfig?.favicon : '/favicon.ico',
      },
      ...hreflang.value,
      canonical.value,
    ]
    if (proAreaStore.getIsProDomain) {
      links.map(x => {
        x.href = x.href.replace('www', 'pro')
        return x
      })
    }
    return {
      htmlAttrs: {
        lang: locale || 'fr',
      },
      __dangerouslyDisableSanitizers: ['script'],
      title,
      description,
      meta,
      link: links,
      script: scripts,
    }
  }

  const defaultSeo = () => {
    const title = setTitle()
    const description = setDescription()
    return {
      charset: 'utf-8',
      viewport: 'width=device-width, initial-scale=1, maximum-scale=1.0',
      ogType: 'website',
      title,
      ogTitle: title,
      description,
      ogDescription: description,
      ogImage: dynamicMeta?.value?.ogImage || 'https://d3brsr9pdomwt0.cloudfront.net/social/web_banner.jpg',
      googleSiteVerification: config.public.GOOGLE_VERIFICATION,
    }
  }

  return {
    defaultHead,
    defaultSeo,
    noIndex,
    customScript,
    customKey,
    customTarget,
    dynamicMeta,
    reference,
    category,
    brand,
    merchant,
    getCurrentUrl,
  }
}
