<template>
  <component
    :is="tag === 'nuxt-link' ? nuxtLinkComponent : tag"
    class="btn"
    :class="[
      classVariant,
      hasSlot && !label ? 'icon-only' : '',
      hasSlot || $slots?.iconLeft || $slots?.iconRight ? 'has-icon' : '',
      disabled ? 'disabled' : '',
      tooltip ? 'tooltip' : '',
      (hasSlot && iconPosition === 'LEFT') || $slots?.iconLeft ? 'il' : '',
      (hasSlot && iconPosition === 'RIGHT') || $slots?.iconRight ? 'ir' : '',
      hasEmoji ? 'has-emoji' : '',
    ]"
    :to="link"
    :href="link"
    :target="target"
    :disabled="disabled"
    :style="[outlinedColor ? '--outlinedColor' : '', ...(styleVariant || [])]"
    :title="title"
    :aria-label="ariaLabel"
  >
    <slot v-if="iconPosition === 'LEFT'" />
    <slot name="iconLeft" />
    <div v-if="label && loading" class="loader" />
    <span v-else-if="label" class="btn-label">{{ label }}</span>
    <slot v-if="iconPosition === 'RIGHT'" />
    <slot name="iconRight" />
    <span v-if="badge && badge > 0" class="btn-badge" :data-test-id="elementTest + '-badge'">
      {{ badge }}
    </span>
    <span v-if="tooltip" class="btn-tooltip" v-html="tooltip" />
    <div v-if="hasPremiumIcon" class="btn-premium-icon">
      <img src="~/assets/img/svg/icons/brand/ico-brand-yellow.svg" />
    </div>
  </component>
</template>

<script>
export default {
  name: 'MainButton',
  props: {
    label: { type: String, default: null },
    badge: { type: String, default: null },
    title: { type: String, default: null },
    ariaLabel: { type: String, default: null },
    link: { type: [String, Object], default: null },
    target: { type: String, default: null },
    tag: { type: String, default: 'nuxt-link' }, // nuxt-link or any tag
    tooltip: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    iconPosition: { type: String, default: 'LEFT' }, // LEFT, RIGHT
    classVariant: { type: null, default: null }, // See bellow
    styleVariant: { type: null, default: () => {} },
    loading: { type: Boolean, default: false },
    hasPremiumIcon: { type: Boolean, default: false },
    hasEmoji: { type: Boolean, default: false },
    outlinedColor: { type: String, default: null },
    elementTest: { type: String, default: '' },
  },
  setup() {
    const nuxtLinkComponent = resolveComponent('nuxt-link')
    return {
      nuxtLinkComponent,
    }
  },
  computed: {
    hasSlot() {
      return this.$slots.default !== null && this.$slots.default !== undefined
    },
  },
}
</script>

<style lang="scss">
.btn {
  --icon-spacing: 8px;
  --icon-spacing-sm: 4px;
  --border-radius: 50px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: var(--border-radius);
  padding: 11px 25px;
  background-color: var(--primary);
  color: var(--white);
  font-weight: 500;
  font-size: 0;
  text-align: center;
  text-decoration: none;
  min-width: 45px;
  height: 45px;
  overflow: visible;
  cursor: pointer;
  transition: all var(--transition-duration) var(--transition-timing-function);
  white-space: normal;

  &-premium-icon {
    position: relative;
    top: -5px;
    right: -5px;
  }

  &.bg-transparent {
    background-color: transparent !important;
  }

  @include mq($mq-sm) {
    padding: 11px 20px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--white);
    border-radius: var(--border-radius);
    z-index: 0;
    opacity: 0;
    transition: opacity var(--transition-duration) var(--transition-timing-function);
  }

  .btn-badge {
    position: absolute;
    top: -2px;
    right: -2px;

    display: flex;
    align-content: center;
    justify-content: center;

    height: 16px;
    min-width: 16px;
    padding: 0 4px;
    border-radius: 10px;

    background-color: var(--primary);
    color: var(--white);

    font-size: pxToRem(12px);

    z-index: 1;
  }

  .btn-label {
    position: relative;
    z-index: 1;
    font-size: 1rem;
    vertical-align: middle;
    flex: 1 1 auto;
    white-space: nowrap;
    font-weight: 400;
  }

  &.hide-label-md {
    @include mq($mq-sm) {
      .btn-label {
        display: none;
      }
    }
  }

  svg {
    position: relative;
    z-index: 1;
    vertical-align: middle;

    path {
      fill: currentColor;
    }
  }

  img {
    margin: 0 auto;
  }

  &:hover {
    &::before {
      opacity: 0.15;
    }
  }

  &.btn-medium {
    height: 40px;
    min-width: 40px;

    @include mq($mq-lg) {
      .btn-label {
        font-size: pxToRem(15px);
      }
    }
  }

  &.btn-small {
    height: 38px;
    min-width: 38px;

    @include mq($mq-lg) {
      .btn-label {
        font-size: pxToRem(12px);
      }
    }
  }

  &.btn-large {
    padding: $spacing-lg $spacing-md;
    .btn-label {
      font-size: pxToRem(20px);
      font-weight: 800;
      white-space: pre-wrap;
      @include mq($mq-md) {
        font-size: pxToRem(15px);
      }
    }
  }
  &.btn-bold {
    .btn-label {
      font-weight: 800;
    }
  }

  &.btn-hover-green:hover {
    background-color: var(--green-hover);
  }

  &.btn-hover-primary:hover {
    background-color: var(--primary);
  }

  &.btn-hover-label {
    .btn-label {
      max-width: 0;
      margin-left: 0 !important;
      white-space: nowrap;
      overflow: hidden;
      transition: all 0.3s ease-in-out;

      @include mq($mq-md) {
        display: none;
      }
    }

    img {
      margin-right: 0;
      transition: all 0.3s ease-in-out;
    }
  }

  &.btn-hover-label:hover {
    .btn-label {
      max-width: 15rem;
    }

    img {
      margin-right: 7px;

      @include mq($mq-md) {
        margin-right: 0;
      }
    }
  }

  &.btn-text {
    background-color: transparent;
    color: var(--night-blue);
    padding: 5px 10px;
    height: initial;
    font-size: pxToRem(15px);
    &.active {
      background-color: var(--light-green);
      &.club {
        background-color: var(--primary);
        color: var(--white);
      }
    }
    .btn-label {
      font-size: pxToRem(15px);
    }
  }

  &.btn-text-grey {
    background-color: transparent;
    color: var(--steel-grey);
    padding: 5px 10px;
    height: initial;
    font-size: pxToRem(15px);
    text-decoration: underline;
    font-weight: 800;
    &.active {
      background-color: var(--light-green);
      &.club {
        background-color: var(--primary);
        color: var(--white);
      }
    }
    .btn-label {
      font-size: pxToRem(15px);
    }
  }

  &.btn-text-dark-blue {
    background-color: transparent;
    color: var(--dark-blue);
    padding: 5px 10px;
    height: initial;
    font-size: pxToRem(15px);
    text-decoration: underline;
    font-weight: 800;
    &.active {
      background-color: var(--light-green);
      &.club {
        background-color: var(--primary);
        color: var(--white);
      }
    }
    .btn-label {
      font-size: pxToRem(15px);
    }
  }

  &.btn-radius-xs {
    border-radius: $spacing-xs;
    --border-radius: $spacing-xs;
  }
  &.btn-radius-sm {
    border-radius: $spacing-sm;
    --border-radius: $spacing-sm;

    &::before {
      border-radius: $spacing-sm;
    }
  }

  &.btn-white {
    background-color: var(--white);
    color: var(--primary);

    &::before {
      background-color: var(--primary);
    }
  }

  &.btn-white-blue {
    background-color: var(--white);
    color: var(--dark-blue);
  }

  &.btn-light {
    background-color: var(--background-grey);
    color: var(--primary);
  }

  &.btn-grey {
    background-color: var(--bg-grey);
    color: var(--dark-blue);
  }

  &.btn-light-grey {
    background-color: var(--background-grey);
    color: var(--steel-grey);
  }

  &.btn-white-steel-grey {
    background-color: var(--white);
    color: var(--steel-grey);
    border: 2px solid var(--steel-grey);
  }

  &.btn-white-light-grey-border {
    background-color: var(--white);
    color: var(--dark-blue);
    border: 1px solid var(--light-grey);
  }

  &.btn-white-primary {
    background-color: var(--white);
    color: var(--primary);
    border: 2px solid var(--primary);
  }

  &.btn-white-orange {
    background-color: var(--white);
    color: var(--orange);
    border: 2px solid var(--orange);
  }

  &.btn-bg-grey-blue {
    background-color: var(--background-grey);
    color: var(--dark-blue);
  }

  &.btn-light-grey-blue {
    background-color: var(--light-grey);
    color: var(--dark-blue);
  }

  &.btn-light-grey-white {
    background-color: var(--light-grey);
    color: var(--white);
  }

  &.btn-bg-blue {
    background-color: var(--background-blue);
    color: var(--night-blue);

    &::before {
      background-color: var(--primary);
    }
  }

  &.btn-primary-dark-blue {
    background-color: var(--light-green);
    color: var(--dark-blue);
  }

  &.btn-dark-blue-white {
    background-color: var(--dark-blue);
    color: var(--white);
    border: 2px solid var(--white);
  }

  &.btn-white-dark-blue {
    background-color: var(--white);
    color: var(--dark-blue);
    border: 2px solid var(--dark-blue);
  }

  &.btn-white-op {
    background-color: var(--light-green);
    color: var(--white);
  }

  &.btn-blue {
    background-color: var(--night-blue);
    color: var(--white);
  }

  &.btn-clear-blue {
    background-color: var(--clear-blue);
    color: var(--white);
  }

  &.btn-secondary {
    background-color: var(--secondary);
    color: var(--white);
  }

  &.btn-green {
    background-color: var(--green);
    color: var(--white);
  }

  &.btn-twilight {
    background-color: var(--twilight-blue);
    color: var(--white);
  }

  &.btn-yellow {
    background-color: var(--yellow);
    color: var(--night-blue);
  }

  &.btn-grapefruit {
    background-color: var(--grapefruit);
    color: var(--white);
  }
  &.btn-night-blue {
    background-color: var(--night-blue);
    color: var(--white);
  }
  &.btn-dark-blue {
    background-color: var(--dark-blue);
    color: var(--white);
  }

  &.btn-hover-primary {
    &:hover {
      background-color: var(--primary);
      color: var(--white);
    }
  }

  &.btn-hover-light-grey {
    &:hover {
      background-color: var(--light-grey);
      color: var(--dark-blue);
    }
  }

  &.btn-hover-dark-blue {
    &:hover {
      background-color: var(--dark-blue);
      color: var(--white);
    }
  }

  &.btn-hover-white-blue {
    &:hover {
      background-color: var(--white);
      color: var(--dark-blue);
    }
  }

  &.btn-cart {
    background-color: var(--white);
    color: var(--steel-grey);

    &:hover {
      background-color: var(--twilight-blue);
      color: var(--white);
    }
  }

  &.btn-steel {
    background-color: var(--steel-grey);
    color: var(--white);
  }

  &.btn-facebook {
    background-color: #2f74cc;
    color: var(--white);
  }

  &.btn-linkedin {
    background-color: #288fb2;
    color: var(--white);
  }

  &.btn-outlined {
    background-color: transparent !important;
    border-color: var(--outlinedColor);
    border-style: solid;
    border-width: 2px;
  }

  &.tooltip {
    outline: none;

    .btn-tooltip {
      background: var(--night-blue);
      border-radius: $border-radius-boxes;
      box-shadow: 0 2px 24px 0 rgba(5, 23, 47, 0.3);
      text-align: left;
      padding: 20px;
      position: absolute;
      top: calc(100% + 5px);
      left: 50%;
      transform: translate(-50%, 0);
      width: 100%;
      z-index: 2;
      opacity: 0;
      visibility: hidden;
      transition:
        opacity var(--transition-duration) var(--transition-timing-function),
        visibility var(--transition-duration) var(--transition-timing-function);

      * {
        color: var(--white);
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translate(-50%, 0);
        width: 0;
        height: 0;
        border-bottom: 12px solid var(--night-blue);
        border-right: 12px solid transparent;
        border-left: 12px solid transparent;
      }
    }

    &:hover {
      .btn-tooltip {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &.disabled-look {
    opacity: 1 !important;
    .btn-label {
      color: var(--steel-grey);
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.btn-burger {
    border-radius: 0 5px 5px 0;
    padding-left: 20px;
    padding-right: 20px;

    @include mq($mq-sm) {
      min-width: 45px;
      padding-left: 0;
      padding-right: 0;
    }

    &::before {
      border-radius: 0 5px 5px 0;
    }

    .btn-label {
      @include mq($mq-sm) {
        display: none;
      }
    }

    svg {
      @include mq($mq-sm) {
        margin: 0 auto;
      }
    }
  }

  &.full-height {
    height: 100%;
  }

  &.full-width {
    display: flex;
    width: 100%;
    flex: 1;
    justify-content: space-around;
  }

  &.full-width-md {
    @include mq($mq-md) {
      display: flex;
      width: 100%;
    }
  }

  &.full-width-sm {
    @include mq($mq-sm) {
      display: flex;
      width: 100%;
    }
  }

  &.full-width-xs {
    @include mq($mq-xs) {
      display: flex;
      width: 100%;
    }
  }

  &.il {
    .btn-label {
      margin-left: var(--icon-spacing);

      @include mq($mq-sm) {
        margin-left: var(--icon-spacing-sm);
      }
    }
  }

  &.ir {
    .btn-label {
      margin: 0 var(--icon-spacing);

      @include mq($mq-sm) {
        margin: 0 var(--icon-spacing-sm);
      }
    }
  }

  &.sp {
    padding-left: 5px;
    padding-right: 5px;
  }

  &.min {
    padding-left: 10px;
    padding-right: 10px;

    .btn-label {
      font-size: pxToRem(14px);
    }
  }

  &.icon-md {
    img {
      height: 28px;
      width: 28px;
    }
  }

  &.btn-icon {
    color: var(--dark-blue);
    background-color: transparent;

    &:hover {
      background-color: var(--bg-grey);
    }
  }

  &.icon-only {
    padding-left: 0 !important;
    padding-right: 0 !important;
    justify-content: center;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.has-emoji {
    margin-left: $spacing-sm;
    box-shadow: 0px 5px 30px rgba(5, 23, 47, 0.15);
    &.full-width {
      width: calc(100% - 40px);
    }
  }

  &.has-icon {
    padding: 0 $spacing-sm;
    &.btn-medium {
      padding: $spacing-xs $spacing-sm;
    }
    &.btn-large {
      padding: $spacing-md $spacing-sm;
    }
  }

  &.btn-collapse {
    padding-left: 15px;
    padding-right: 15px;

    .btn-label {
      max-width: 0;
      opacity: 0;
      display: inline-block;
      overflow: hidden;
      margin-left: 0;
      margin-right: 0;
      transition:
        max-width 0.5s var(--transition-timing-function),
        margin 0.5s var(--transition-timing-function),
        opacity var(--transition-duration) var(--transition-timing-function);
    }

    &:hover {
      &::before {
        opacity: 0;
      }

      .btn-label {
        max-width: 100px;
        opacity: 1;
        transition:
          max-width 0.75s var(--transition-timing-function),
          margin 0.75s var(--transition-timing-function),
          opacity var(--transition-duration) var(--transition-timing-function);
      }

      &.il {
        .btn-label {
          margin-left: var(--icon-spacing);

          @include mq($mq-sm) {
            margin-left: var(--icon-spacing-sm);
          }
        }
      }

      &.ir {
        .btn-label {
          margin-right: var(--icon-spacing);

          @include mq($mq-sm) {
            margin-right: var(--icon-spacing-sm);
          }
        }
      }
    }
  }
}

.btn-wrapper {
  display: flex;

  .btn {
    margin-right: 20px;

    @include mq($mq-sm) {
      margin-right: 8px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
  .btn-stacked {
    flex: 1;
    margin-right: 0px;
    &::before {
      border-radius: 0;
    }
    &:first-child {
      border-radius: 5px 0px 0px 5px;
    }
    &:last-child {
      border-radius: 0 5px 5px 0;
    }
  }
  .btn-alongside {
    flex: 1;
  }
}
</style>
