import { defineStore } from 'pinia'

const state = () => ({
  domain: null,
  uid: null,
  name: null,
  kind: null,
  key: null,
  logo_url: null,
  description: null,
  company_group_configuration: {},
  current_user_roles: {},
})

const getters = {
  isClub: state => {
    return state.domain != null && state.domain != undefined && state.domain != ''
  },
  currentClub: state => {
    return state
  },
  layout: state => {
    return getters.isClub(state) ? 'club' : 'default'
  },
  clubEnablePayment: state => {
    // return if payment is processed in club by StockPro (true) or by club owner
    return state.company_group_configuration?.payment_policy != 'BOOK'
  },
  clubEnableDelivery: (/* state */) => {
    // return if delivery is processed in club by StockPro (true) or by club owner
    // return state.company_group_configuration.delivery_policy != 'EXTERNAL'
    return false
  },
  clubUid(state) {
    return state.uid
  },
  clubConfig(state) {
    return {
      ...state.company_group_configuration,
      // Mock data
      favicon: 'https://res.cloudinary.com/hwpsb8mtr/image/upload/company_groups/qa/pam-favicon.png',
    }
  },
  clubBanners(state) {
    if (getters.clubConfig(state)?.banner) {
      return [
        {
          component: 'Slide',
          image_alt: 'club banner',
          mobile_image: getters.clubConfig(state)?.banner,
          wide_image: getters.clubConfig(state)?.banner,
          testId: 'club-banner',
        },
      ]
    } else if (getters.clubConfig(state)?.banners) {
      if (getters.clubConfig(state).banners.some(x => x.desktop_image_url || x.mobile_image_url)) {
        return getters.clubConfig(state).banners.map(banner => {
          return {
            component: 'Slide',
            image_alt: banner.alt,
            mobile_image: banner.mobile_image_url,
            target_value: banner.url,
            wide_image: banner.desktop_image_url,
            testId: 'club-banner',
          }
        })
      }
    }
    return null
  },
  clubAds(state) {
    if (getters.clubConfig(state)?.product_ads?.length) {
      return getters.clubConfig(state)?.product_ads.map(ad => {
        return {
          _uid: ad._uid,
          component: 'GridAd',
          desktop_index: ad.line,
          format: ad.kind === 'LANDSCAPE' ? 'full' : ad.position ? ad.position?.toLowerCase() : null,
          mobile_image: ad.image_url,
          mobile_index: ad.line,
          url: ad.url,
          wide_image: ad.image_url,
        }
      })
    }
  },
}

const actions = {
  async getClub() {
    try {
      const { $api } = useNuxtApp()
      await $api()
        .club.get()
        .then(rawClub => {
          if (!rawClub) throw 'error'
          this.setClub(rawClub)
        })
        .catch(e => {
          console.log('error get raw club', e)
        })
    } catch (e) {
      console.log(e)
      throw createError({ statusCode: 404, message: e.message })
    }
  },
  setClubDomain(domain) {
    this.domain = domain
  },
  setClub(club) {
    // console.log('setClub', club)
    if (club) {
      this.uid = club.uid
      this.name = club.name
      this.kind = club.kind
      this.key = club.key
      this.description = club.description
      this.company_group_configuration = club.company_group_configuration
      this.current_user_roles = club.current_user_roles
      this.logo_url = club.company_group_configuration?.logo_url
    }
  },
}

export const useClubStore = defineStore('clubStore', {
  state,
  getters,
  actions,
})
