import { useClubStore } from '@/stores/club'
import { useI18nStore } from '@/stores/i18nStore'
import { useConfigStore } from '@/stores/config'
import { useCartStore } from '@/stores/cart'
import { useActivationStore } from '@/stores/activation'

import { defineStore } from 'pinia'

const state = () => ({
  user: null,
  zipCode: null,
  location: {
    lat: null,
    lng: null,
  },
})

const getters = {
  getUser: state => {
    const { data } = useAuth()
    return state.user || data?.value?.user
  },
  getZipCode: state => state.zipCode,
  getLocation: state => state.location,
  name(state) {
    return state.user?.first_name
  },
  lastName(state) {
    return state.user?.last_name
  },
  fullName(state) {
    return state.user ? `${state.user?.first_name || ''} ${state.user?.last_name || ''}` : null
  },
  uid(state) {
    return state.user?.uid
  },
  email(state) {
    return state.user?.email
  },
  createdAt(state) {
    return state.user?.created_at
  },
  company(state) {
    return state.user?.company
  },
  companyUid(state) {
    return state.user?.company?.uid
  },
  companyName(state) {
    return state.user?.company?.name
  },
  phone(state) {
    return state.user?.phone_number
  },
  token() {
    const { token } = useAuthState()
    return token?.value
  },
  isFreemium(state) {
    return state.user?.company?.status === 'free'
  },
  hasCompanyOptionsDashboard(state) {
    return state.user?.company?.options?.includes('customer_dashboard')
  },
  isValidUser(state) {
    return state.user !== null && state.user !== undefined
  },
  roleName(state) {
    return state.user?.role?.name
  },
  isIndividual(state) {
    return this.isValidUser && state.user?.role?.name === 'Individual'
  },
  isPro(state) {
    return this.isValidUser && state.user?.role?.name !== 'Individual'
  },
  isEmployee(state) {
    return this.isValidUser && state.user?.role?.name === 'Employee'
  },
  isOwner(state) {
    return this.isValidUser && state.user?.role?.name === 'Owner'
  },
  isAdmin(state) {
    return this.isValidUser && state.user?.role?.name === 'Admin'
  },
  isAuthenticated() {
    const { status } = useAuth()
    return status?.value === 'authenticated' && this.isValidUser
  },
  clubs(state) {
    // Filter out other group kind like GROUP
    return this.isValidUser && state.user?.company_groups?.filter(g => g.kind == 'MKP_CLUB' && g.key !== 'stockpro')
  },
  isSalesRep(state) {
    return state.user?.user_roles?.find(role => role?.company?.uid === state.user?.company?.uid)?.is_sales_rep || false
  },
  isPriceTeam(state) {
    return state.user?.user_roles?.find(role => role?.company?.uid === state.user?.company?.uid)?.is_price_team || false
  },
  isSalesAdmin(state) {
    return (
      state.user?.user_roles?.find(role => role?.company?.uid === state.user?.company?.uid)?.is_sales_admin || false
    )
  },
}

const actions = {
  setUser(user) {
    this.user = user
    if (!this.zipCode && this.user?.addresses?.[0]?.zip_code) {
      this.setZipCode(this.user.addresses[0].zip_code)
    }
    if (!this.zipCode && this.user?.company?.addresses?.[0]?.zip_code) {
      this.setZipCode(this.user.company.addresses[0].zip_code)
    }
  },
  setZipCode(zipCode) {
    this.zipCode = zipCode
  },
  setLocation(location) {
    this.location = location
    localStorage.setItem('x-user-location', JSON.stringify(location))
  },
  async login(token, user = {}) {
    this.setLocalToken(null)
    const { setToken } = useAuthState()
    if (token.includes('Bearer')) {
      token = token.split('Bearer ')[1]
    }
    setToken(token)
    const { getSession } = useAuth()
    await getSession()
    const { $api } = useNuxtApp()
    const apiUser = await $api().user.getMe()
    this.setUser(Object.assign(user, apiUser))
    this.setLocalToken(token)
    const activationStore = useActivationStore()
    activationStore.setPhone(null)
    activationStore.setEmailOrPhone(null)
    activationStore.setForgotPassword(false)

    const cartStore = useCartStore()
    // console.log(cartStore.setCartUid)
    if (user?.cart_uid !== null && user?.cart_uid !== undefined && typeof cartStore.setCartUid === 'function') {
      cartStore.setCartUid(user?.cart_uid)
    } else if (typeof cartStore.setCartUid === 'function') {
      cartStore.setCartUid(null)
    }

    const configStore = useConfigStore()
    if (this.isPro) {
      configStore.add({ showVAT: false })
    } else {
      configStore.add({ showVAT: true })
    }

    const clubStore = useClubStore()
    if (clubStore.isClub) {
      clubStore.getClub()
    }
  },
  logout() {
    return new Promise((resolve, reject) => {
      this.setLocalToken(null)
      try {
        const { clearToken } = useAuthState()
        clearToken()
        this.clearUser()
        resolve()
      } catch (e) {
        reject(e)
      }
    })
  },
  setLocalToken(token) {
    const i18nStore = useI18nStore()
    const country = i18nStore.country
    if (token) {
      const authCookie = useCookie('auth.token', { maxAge: 60 * 60 * 24 * 6, domain: `.${country?.host}` })
      authCookie.value = token
    } else {
      const authCookie = useCookie('auth.token', { maxAge: 60 * 60 * 24 * 6, domain: `.${country?.host}` })
      authCookie.value = null
    }
  },
  clearUser() {
    this.setUser(null)
    reloadNuxtApp()
  },
}

export const useUserStore = defineStore('userStore', {
  state,
  getters,
  actions,
})
