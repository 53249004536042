import * as Sentry from '@sentry/vue'
import { useSnackbarStore } from '@/stores/snackbar'

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.hook('app:error', error => {
    console.log('Global error handler:', error)
    const snackbarStore = useSnackbarStore()
    const country = useCurrentLocale()
    snackbarStore.showSnackbar({
      color: 'grapefruit',
      message: nuxtApp.$i18n.t('backendError.unknown', { phone: country?.phoneNumber }),
    })
    if (!useRuntimeConfig().public.SENTRY_DISABLED) {
      Sentry.captureException(error)
    }
    throw error
  })
})
