import { defineStore } from 'pinia'

export const useActivationStore = defineStore('activationStore', () => {
  const phone = ref(null)
  const emailOrPhone = ref(null)
  const forgotPassword = ref(false)

  const getPhone = computed(() => phone.value)
  const getEmailOrPhone = computed(() => emailOrPhone.value)
  const getForgotPassword = computed(() => forgotPassword.value)

  const setPhone = value => {
    phone.value = value
  }
  const setEmailOrPhone = value => {
    emailOrPhone.value = value
  }
  const setForgotPassword = value => {
    forgotPassword.value = value
  }

  return {
    phone,
    emailOrPhone,
    forgotPassword,
    getPhone,
    getEmailOrPhone,
    getForgotPassword,
    setPhone,
    setEmailOrPhone,
    setForgotPassword,
  }
})
