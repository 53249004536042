import { defineStore } from 'pinia'

export const useSnackbarStore = defineStore('snackbar', {
  state: () => ({
    show: false,
    color: '',
    message: '',
    timeout: 2000,
    canBeClosed: true,
    customTop: null,
  }),

  getters: {
    getShow(state) {
      return state.show
    },
    getColor(state) {
      return state.color
    },
    getMessage(state) {
      return state.message
    },
    getTimeout(state) {
      return state.timeout
    },
    getCanBeClosed(state) {
      return state.canBeClosed
    },
    getCustomTop(state) {
      return state.customTop
    },
  },

  actions: {
    hideSnackbar() {
      this.show = false
    },
    showSnackbar(param) {
      this.color = param.color
      this.message = param.message
      this.timeout = param.timeout || 2000
      this.show = true
      this.canBeClosed = param.canBeClosed
      this.customTop = param.customTop
    },
  },
})
